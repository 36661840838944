<template>
  <van-collapse class="nmg-account-detail" v-model="activePlacing" accordion @transitionend="onTransitionend">
    <van-collapse-item name="1">
      <template v-slot:title>
        <span class="charge__detail__label">投放账户ID</span>{{mediaPlacingAccIdInput}}
      </template>
      <!-- 金额 -->
      <div class="charge__detail__money"> 

        <div class="charge__detail__money-item" v-if="undefined !== mediaAccBalance">
          <div class="left">
            <i class="iconfont icon-kechongjine"></i>
          </div>
          <div class="right">
            <p class="charge__detail__money-label">可充金额</p>
            <p class="charge__detail__money-value">{{mediaAccBalance || 0}}</p>
          </div>
        </div>

        <div class="charge__detail__money-item" v-if="undefined !== distributableBalance">
            <div class="charge__detail__money-content">
              <div class="left">
                <i class="iconfont icon-zhanghuyue"></i>
              </div>
              <div class="right">
                <p class="charge__detail__money-label">客户总余额</p>
                <p class="charge__detail__money-value">{{distributableBalance || 0}}</p>
              </div>
            </div>
        </div>

        <div class="charge__detail__money-item" v-if="undefined !== applyPrice">
          <div class="left">
            <i class="iconfont icon-kechongjine"></i>
          </div>
          <div class="right">
            <p class="charge__detail__money-label">充值金额</p>
            <p class="charge__detail__money-value">{{applyPrice || 0}}</p>
          </div>
        </div>

        

      </div>

      <!-- other -->
      <div class="charge__detail__info" v-for="(item, i) in other" :key="i"> 
        <div class="charge__detail__label">{{item.label}}</div>
        <div class="charge__detail__value">{{item.value}}</div>
      </div>

    </van-collapse-item>
  </van-collapse>
</template>

<script>
import './assets/less/index.less'
export default {

  name: 'NmgAccountDetail',

  props: {
    data: Object,
    // 投放账户ID
    mediaPlacingAccIdInput: {},
    // 可充金额
    mediaAccBalance: {},
    // 充值金额
    applyPrice: {},
    // 客户总余额
    distributableBalance: {},
    // 其他信息
    other: {
      type: Array,
    }
  },

  data() {
    return {
      // 激活的投放账户
      activePlacing: '1',
    }
  },

  methods: {
    // *************************************************** ENENT ***************************************************
    onTransitionend() {
      this.$emit('transitionend');
    }
  }
}
</script>

<style>

</style>