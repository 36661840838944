import './assets/less/index.less'
import { defineComponent } from 'vue'

export default defineComponent({

  name: 'NmgNavMedia',

  props: {
    modelValue: String
  },

  emits: ['update:modelValue'],

  data() {
    return {
      active: 'ks'
    }
  },

  methods: {
    // ********************************************************* RENDER *********************************************************
    renderTitle() {
      return (
        <van-tabs
          v-model:active={this.active} 
          onChange={this.onChage}
          swipe-threshold="2"
          class="van-tabs--ball"
        >
          <van-tab title="快手" name="ks"></van-tab>
          <van-tab title="抖音" name="tt"></van-tab>
          <van-tab title="广点通" name="tencent"></van-tab>

          {/* <van-tab title="快手-竞价" name="6DCBF78511D8BD7DE050007F010034A6"></van-tab>
          <van-tab title="快手-磁力金牛" name="C6FB25E1F7F42E94E050A8C06A1027A6"></van-tab>
          <van-tab title="抖音-竞价" name="7B2AF195E8243606E05064ACFD154E37"></van-tab>
          <van-tab title="广点通-竞价" name="7516F461BBA84C9EE05064ACFD153D74"></van-tab> */}
        </van-tabs>
      )
    },
    // ********************************************************* FUNCTION *********************************************************
    // ********************************************************* EVENT *********************************************************
    onChage(value) {
      this.$emit('update:modelValue', value);
      this.$emit('change', value);
    }
  },

  render() {
    // <van-nav-bar
    //   @click-left="onClickLeft"
    //   @click-right="onClickRight"
    // />
    let slots = {
      title: () => this.renderTitle()
    }

    if( this.$slots.left ) {
      slots.left = () => this.$slots.left();
    }

    if( this.$slots.right ) {
      slots.right = () => this.$slots.right();
    }

    return (
      <van-nav-bar
        class="nmg-nav-media"
        v-slots={slots}
        >
      </van-nav-bar>
    );
  }

})
