export default [
    {
        path: '/login',
        component: () => import('/src/views/login/login.vue'),
        name: '/login',
        meta: {
            name: '登录页'
        }
    }
];
