export default [
    {
        path: '/audit/detail',
        name: '/audit/detail',
        component: () => import('/src/views/audit/detail/detail.vue'),
        meta: {
            name: '审核详情'
        }
    },
    {
        path: '/audit/searchPage',
        name: '/audit/searchPage',
        component: () => import('/src/views/audit/searchPage/searchPage.vue'),
        meta: {
            name: '搜索审核记录'
        }
    }
    
    
];
