// ************************************************** 基础组件 **************************************************
// 块
import block from './block/index.js';
// 投放账户列表
import list from './list/index.js';
import tabs from './tabs/index.js';
import tab from './tab/index.js';
import field from './field/index.js';

// ************************************************** 基础组件 **************************************************



// ************************************************** 高级组件 **************************************************
// 投放账户卡片
import accountCard from './accountCard/index.js';
// 投放账户详情
import accountDetail from './accountDetail/index.js';
// 媒体导航
import navMedia from './navMedia/index.js';
// 搜索页
import searchPage from './searchPage/index.js';
// 搜索結果页
import searchPageResult from './searchPageResult/index.js';

// ************************************************** 高级组件 **************************************************



const components = [
    block,
    list,
    tabs,
    tab,
    field,

    accountCard,
    accountDetail,
    navMedia,
    searchPage,
    searchPageResult,
];

export default function (Vue) {
    // 安装组件
    components.forEach(current => current.install(Vue));
}

