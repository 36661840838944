/* 公共请求 */
import axios from 'axios'
//引入Loading服务
import { Toast  } from 'vant'; 

const TIME_OUT_MS = 10000000 // 默认请求超时时间

axios.defaults.baseURL = '/api/'; // api 就是 vue.config.js 中配置的地址
const CancelToken = axios.CancelToken;
/*
 *   请求成功返回值处理函数
 * @param { Object } response 响应对象
 * @param { Object } ownOptions 自用配置对象
 */
function handleReslove( response, ownOptions ) {
	let data = response.data|| {}
    let ownOptionsDefault = {
        successDefault: true,// 支持成功默认操作
    };
    ownOptionsDefault = Object.assign( {}, ownOptionsDefault, ownOptions );

    if ( ownOptionsDefault.successDefault && ( data.status === "success" || data.status === true || data.code === 200 || data.code === 0 ) ) {
         
    } else if(data.status === "sessionLose"){
		window.location.href = window.location.origin;
	} else {
        return false;
    }
    return true;
}

/*
 * 请求失败返回值处理函数
 * @param { Object } response 响应对象
 * @param { Object } ownOptions 自用配置对象
 */
function handleReject( response, ownOptions ) {
	let data = response.data || {}
    let ownOptionsDefault = {
        errorDefault: true,// 支持失败默认操作
    };
    ownOptionsDefault = Object.assign( {}, ownOptionsDefault, ownOptions );

    if(data.status === "sessionLose"){
		window.location.href = window.location.origin;
	} else if( ownOptionsDefault.errorDefault ) {
        Toast.fail(data.message || data.errmsg);
	}
}

/**
 * post 请求
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @param {Object} options axios配置对象
 * @param {Object} ownOptions 自用配置对象
 */
export const post = ( url, data = {}, options = {}, ownOptions = {} ) => {
    let cancel;
    let p = new Promise(( reslove, reject ) => {
        let defaultOptions = {
            method: 'post',
            url: url,
            data: data,
            timeout: TIME_OUT_MS,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            cancelToken: new CancelToken(c => cancel = c)
        };

        let a = axios( Object.assign( {}, defaultOptions, options ) )
        .then((result) => {
            if( handleReslove( result , ownOptions ) ) {
                reslove( result );
            } else {
                handleReject( result , ownOptions );
                reject( result );
            }
            
        })
        .catch((error) => {
            handleReject( error , ownOptions );
            reject( error );
        });
    });
    p.cancel = cancel;
    return p;
}

/**
 *  get 请求
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @param {Object} options axios配置对象
 * @param {Object} ownOptions 自用配置对象
 */
export const get = ( url, data = {}, options = {}, ownOptions = {} ) => {
    let defaultOptions = {
        method: 'get',
        url: url,
        params: data,
        timeout: TIME_OUT_MS,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        }
    };
    return new Promise(( reslove, reject ) => {
        axios( Object.assign( {}, defaultOptions, options ) )
        .then((result) => {
            if( handleReslove( result , ownOptions ) ) {
                reslove( result );
            } else {
                handleReject( result , ownOptions );
                reject( result );
            }
        })
        .catch((error) => {
            handleReject( error , ownOptions );
            reject( error );
        })
    });
}

/**
 * 上传文件
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @param {Object} options axios配置对象
 * @param {Object} ownOptions 自用配置对象
 */
export const uploadFile = ( url, data = {}, options = {}, ownOptions = {} ) => {
    let defaultOptions = {
        method: 'post',
        url: url,
        data: data,
        dataType: 'json',
        processData: false, // 禁止以对象的形式上传的数据被转换为字符串的形式上传
        contentType: false, // 因为要传输文件所以改掉之前默认的数据格式
    };

    // uploadFileFormData
    // headers: {
    //     'Content-Type': 'multipart/form-data'
    // }
    return new Promise(( reslove, reject ) => {
        axios( Object.assign( {}, defaultOptions, options ) )
        .then((result) => {
            if( handleReslove( result , ownOptions ) ) {
                reslove( result );
            } else {
                handleReject( result , ownOptions );
                reject( result );
            }
        })
        .catch((error) => {
            handleReject( error , ownOptions );
            reject( error );
        })
    });
}

/**
 * 上传文件FormData
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @param {Object} options axios配置对象
 * @param {Object} ownOptions 自用配置对象
 */
 export const uploadFileFormData = ( url, data = {}, options = {}, ownOptions = {} ) => {
    let defaultOptions = {
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return new Promise(( reslove, reject ) => {
        axios( Object.assign( {}, defaultOptions, options ) )
        .then((result) => {
            if( handleReslove( result , ownOptions ) ) {
                reslove( result );
            } else {
                handleReject( result , ownOptions );
                reject( result );
            }
        })
        .catch((error) => {
            handleReject( error , ownOptions );
            reject( error );
        })
    });
}

/**
 * 下载文件
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @param {Object} options axios配置对象
 * @param {Object} ownOptions 自用配置对象
 */
 export const downloadFile = ( url, data = {}, options = {}, ownOptions = {}) => {
    let defaultOptions = {
        method: 'post',
        url: url,
        data: data,
        responseType: 'blob' // 返回响应数据的类型
    };
    return new Promise(( reslove, reject ) => {
        axios( Object.assign( {}, defaultOptions, options ) )
        .then((result) => {

            // 下载文件
            if( result.headers['content-type'].match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ) {
                const excelBlob = result.data;
                let fileName =   'newFile.xlsx';
                if( ownOptions.fileName ) {
                    fileName = ownOptions.fileName;
                } else if(result.headers['content-disposition']) {
                    fileName = decodeURIComponent(result.headers['content-disposition'].split(';')[1].split('=')[1]);
                }
                /* 兼容处理 */
                if ('msSaveOrOpenBlob' in navigator) {
                    // 微软Edge和微软Internet Explorer 10-11
                    window.navigator.msSaveOrOpenBlob(excelBlob, fileName)
                } else {
                    const elink = document.createElement('a')
                    elink.download = fileName
                    elink.style.display = 'none'
                    const blob = new Blob([excelBlob])
                    elink.href = URL.createObjectURL(blob)// 创建下载的链接
                    document.body.appendChild(elink)
                    elink.click()
                    document.body.removeChild(elink)
                }
                reslove( result );
            } else {
                reject( result );
            }
        })
        .catch((error) => {
            handleReject( error , ownOptions );
            reject( error );
        })
    });
}



