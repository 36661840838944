<template>
  <div class="nmg-search-page">

    <!-- 搜索框 -->
    <van-sticky class="nmg-search-page__nav-sticky">

      <van-nav-bar
        left-arrow
        @click-left="onClickLeft"
        :class="{'has-right': !!$slots.right}"
      >
        <template #title>
          <van-search
            ref="search"
            v-model="modelValue"
            :placeholder="placeholder"
            @click="onClickSearchInput"
            @search="onSearch"
          >
          <template #left-icon>
            <i class="iconfont icon-chaxun"></i>
          </template>
          </van-search>
        </template>

        <template #right v-if="$slots.right">
          <slot name="right"></slot>
        </template>

      </van-nav-bar>
      
    </van-sticky>

    <!-- 最近搜索 -->
    <van-sticky :offset-top="48" v-if="searchHistory.length">
      <div class="lately">
        <div class="lately__bar">
          <div class="left">最近搜索</div>
          <div class="right"><i class="iconfont icon-qingkong" @click="onClear"></i></div>
        </div>
        <ul class="lately__history">
          <li class="lately__history-item" v-for="(item, i) in searchHistory" :key="i" @click="onClick(item)">{{item}}</li>
        </ul>
      </div>
    </van-sticky>

  </div>
</template>

<script>
import './assets/less/index.less'
export default {

  name: 'NmgSearchPage',

  props: {
    history: String,
    placeholder: {
      type: String,
      default: '请输入搜索关键词'
    },
    modelValue: String
  },

  emits: ['search','update:modelValue'], 

  data() {
    return {
      // 搜索历史
      searchHistory: []
    }
  },

  methods: {
    /**
     * public 公开方法
     */
    focus() {
      this.$refs.search.focus();
    },
    // ****************************************************************** EVENT ******************************************************************
    onClickLeft() {
      this.$router.go(-1);
    },
    onSearch(value) {
      if(this.history && value) {
        let searchHistory = this.$localStorage.getItem(this.history) || [];
        if( -1 === searchHistory.indexOf(value) ) {
          searchHistory.push(value);
        }
        this.$localStorage.setItem(this.history, searchHistory);
        this.searchHistory = searchHistory;
      }
      this.$emit('update:modelValue', value);
      this.$emit('search',value);
      
    },
    onClickSearchInput() {
      
    },
    /**
     * 清除历史记录
     */
    onClear() {
      this.searchHistory = [];
      this.$localStorage.removeItem(this.history);
    },
    onClick(item) {
      this.$emit('update:modelValue', item);
      this.$refs.search.focus();
    }

  },

  mounted() {
    const vm = this;
    if(this.history) {
      this.searchHistory = this.$localStorage.getItem(this.history) || [];
    }
    vm.$nextTick(function () {
      vm.$refs.search.focus();
    });

  }

}
</script>

<style>

</style>