<template>
  <!-- 投放账户卡片 -->
  <div class="nmg-account-card">
    <div class="nmg-account-card__wrap">
      <div class="nmg-account-card__title">
        <div class="nmg-account-card__title-left">
          {{mediaPlacingAccIdInput}}
        </div>
        <div class="nmg-account-card__title-right">
          <span v-if="'0' === operateType">客户运营</span>
          <span v-if="'1' === operateType">自运营</span>
          <span v-if="'2' === operateType">三方运营</span>
          <van-badge :content=" useableDays + '天'"/>
        </div>
      </div>
      <div class="nmg-account-card__content">
        <p>{{productName}}</p>
        <p v-if="mediaAccBalance">
          <van-badge dot class="red"/>可充金额：{{mediaAccBalance || 0}}
        </p>
        <p v-if="applyPrice">
          <van-badge dot class="red"/>充值金额：{{applyPrice || 0}}
        </p>
        <p><van-badge dot class="blue"/>客户总余额：{{distributableBalance || 0}}</p>
      </div>
    </div>
</div>
</template>

<script>
import './assets/less/index.less';
export default {
  name: 'NmgAccountCard',
  props: {
    // 媒体投放户ID
    mediaPlacingAccIdInput: {},
    // 产品名称
    productName: {},
    // 运营类型
    operateType: {},
    // 可充天数
    useableDays: {},
    // 可充金额
    mediaAccBalance: {},
    // 充值金额
    applyPrice: {},
    // 客户总余额
    distributableBalance: {},
  }
}
</script>
