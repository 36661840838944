export default [
    {
        path: '/charge/detail',
        name: '/charge/detail',
        component: () => import('/src/views/charge/detail/detail.vue'),
        meta: {
            name: '充值详情'
        }
    },
    {
        path: '/charge/doCharge',
        name: '/charge/doCharge',
        component: () => import('/src/views/charge/doCharge/doCharge.vue'),
        meta: {
            name: '充值'
        }
    },{
        path: '/charge/searchPage',
        name: '/charge/searchPage',
        component: () => import('/src/views/charge/searchPage/searchPage.vue'),
        meta: {
            name: '搜索投放户'
        }
    }
    
];
