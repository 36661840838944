// 一般工具函数（暂时无法归类的函数可放在这里）
import common from './common';
import localStorage from './localStorage';
import sessionStorage from './sessionStorage';
// 表单校验工具
import validate from './validate';
import { throttle, debounce } from 'throttle-debounce';

// Vue对象的全局API
// import { h, nextTick } from 'vue'

const methods = {
  throttle,
  debounce,
  // h,
  // nextTick,
  ...common,
  sessionStorage,
  localStorage,
  ...validate
};



export default function (app) {
    // 添加实例方法
    for (const key in methods) {
      app.config.globalProperties['$' + key] = methods[key]
    }
}