import {createStore} from 'vuex' /* 引入vuex*/
import {deepCopy} from '../tools/common.js' /* 公用工具(日期格式化，对象深拷贝) */

/* 创建Vuex库，就相当于一个前端的数据库 */
const store = createStore({
	state: { /* 数据源 */
		currentUser: null, //当前用户信息,
	},
	mutations: {
        currentUser: (state, data) => {
            state.currentUser = deepCopy(data);
        }
    }
})
export default store;
