import { defineComponent } from 'vue'

export default defineComponent({

  name: 'NmgField',

  props: {
    main: Boolean
  },

  emits: ['update:modelValue', 'input', 'focus', 'blur', 'clear', 'click', 'click-input', 'click-left-icon', 'click-right-icon' ],

  data() {
    return {
      value: ''
    }
  },

  methods: {
    onUpdateModelValue(value) {
      this.$emit('update:modelValue', value);
    }
  },

  render() {
    const vm = this;
    let slots = {};


    if( this.$slots.label ) {
      slots.label = () => this.$slots.label();
    }

    return (

      <div class="nmg-field" class={{'nmg-field--main': this.main}}>

        <van-field
         {...vm.$attrs}
          v-model:value={this.value}
          v-slots={slots}
          onUpdate:model-value={this.onUpdateModelValue}
        />

      </div>
    );
  }

});

