
/**
 * 常见的校验工具方法
 */
 import {getStringLength} from "./common";

/**
 * 校验字符串长度
 */
export function validStringLength(value ,rule) {
    let { max, eqMax = true, min, eqMin = true, } = rule;
    if (value) {
        let length = getStringLength(value);

        if(eqMax) {// 大于等于
            if ( undefined !== max && length > max  ) {
                return false;
            }
        } else {// 大于
            if ( undefined !== max && length >= max  ) {
                return false;
            }
        }

        if(eqMin) {// 小于等于
            if ( undefined !== min && length < min  ) {
                return false;
            }
        } else {// 小于
            if ( undefined !== min && length <= min  ) {
                return false;
            }
        }
        

    }
    return true;
}

/**
 * 校验整数
 */
 export function validNumber( value, rule ) {
    let { max, eqMax = true, min , eqMin = true } = rule;
    let reg = new RegExp( /^[-\+]?\d+$/ );
   if (value) {
        // 校验小数点位数
        if ( !reg.test( value ) ) {
            return false;
        }
        if(eqMax) {// 大于等于
            // 校验数值范围限制
            if( undefined !== parseInt(max) && parseInt(value) > parseInt(max) ){
                return false;
            }
        } else {// 大于
            // 校验数值范围限制
            if( undefined !== parseInt(max) && parseInt(value) >= parseInt(max) ){
                return false;
            }
        }  
        if(eqMin) {// 小于等于
            if( undefined !== parseInt(min) && parseInt(value) < parseInt(min) ) {
                return false;
            }
        } else {// 小于
            if( undefined !== parseInt(min) && parseInt(value) <= parseInt(min) ) {
                return false;
            }
        }
   }
   return true;
}


/**
 * 校验小数
 * 加上eqMax和eqMin是因为，如果规则是，需要输入值大于0（而不是大于等于0时），这时不能通过设置0.01的方式进行限制，
 * 因为0.001仍然是合法值，所以添加了这两个参数
 */
 export function validFloat( value, rule ) {
    // digit:小数点位数
    let { max, eqMax = true, min , eqMin = true, digit } = rule;
    
    let _digit = digit || 2;
    let reg = new RegExp( `^(([0-9][0-9]*)|(([0]\\.\\d{1,${_digit}}|[0-9][0-9]*\\.\\d{1,${_digit}})))$` );
   if (value) {
        // 校验小数点位数
        if ( !reg.test( value ) ) {
            return false;
        }
        if(eqMax) {// 大于等于
            // 校验数值范围限制
            if( undefined !== parseFloat(max) && parseFloat(value) > parseFloat(max) ){
                return false;
            }
        } else {// 大于
            // 校验数值范围限制
            if( undefined !== parseFloat(max) && parseFloat(value) >= parseFloat(max) ){
                return false;
            }
        }  
        if(eqMin) {// 小于等于
            if( undefined !== parseFloat(min) && parseFloat(value) < parseFloat(min) ) {
                return false;
            }
        } else {// 小于
            if( undefined !== parseFloat(min) && parseFloat(value) <= parseFloat(min) ) {
                return false;
            }
        }
   }
   return true;
}



export default {
    // 校验字符串长度
    validStringLength,
    // 校验浮点类型
    validFloat,
    // 校验整数
    validNumber,
}