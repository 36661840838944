import { createApp } from 'vue'
import router from './config/router/router'
import store from './config/store';
import App from './App.vue'
import Vant from 'vant';
// 自定义图标库
import './assets/iconFont/iconfont.css';
import './assets/iconFont/iconfont.js';
// 自定义样式
import './assets/less/index.less';

// 自动配置rem
import './libs/rem.js';
// 自定义组件
import components from './components/';
// 自定义工具函数
import tools from './tools/index';

const app = createApp(App);

// 注册vant全部组件
app.use(Vant);
app.use(router);
app.use(store);
app.use(components);
app.use(tools);


app.mount('#app');

