import {createRouter, createWebHistory} from 'vue-router'
 // 登录模块
import login from './login.js';
 // 充值模块
 import charge from './charge.js';
 // 审核模块
 import audit from './audit.js';
// 递归限制
let deepLimit = 0;
/**
 * 动态载入路由
 */
 async function dynamicLoad( to, from, next ) {
    // 获取全部路由
    let allRoutes = router.getRoutes();
    if(deepLimit > 20)return false;
    if( to.path && '/' !== to.path ) {
        // 1：判断是否具有改路由
        if( !allRoutes.some( current => to.path === current.path )) {
            deepLimit++;
            let path = to.path.replace('/framework','');
            // 获取路由配置文件，向上递增查找
            let targetRoute = await getFileByPath( path );
            if( path !== to.path ) {
                router.addRoute( '/framework',  targetRoute?.default );
            } else {
                router.addRoute( targetRoute.default );
            }
            return to.fullPath;
        }
    }
    deepLimit = 0;
    return false;
}


/**
 * 向上查找路由配置文件
 * @param {String} path 路由路径
 * @returns 
 */
async function getFileByPath( path, component ) {
    let route;
    // let modules;
    try {
        if( component ) {
            route = await import( '../views' + path + '/components/' + component + '/config/route.js' );
        } else {
            route = await import( '../views' + path + '/config/route.js' );
        }
    } catch ( err ) {
        let pathArr = path.split('/');
        let component = pathArr.pop();
        if(pathArr.length) {
            route = await getFileByPath( pathArr.join('/'), component );
        }
    }
    return route;
}

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
	{ path: '/', redirect: '/login' },
	{ 
		path: '/framework', 
		name: '/framework', 
		redirect: '/framework/charge', 
		component: () => import('../../views/framework/framework.vue'),
		children: [
            {
                path: '/framework/charge',
                name: 'charge',
                component: () => import('/src/views/charge/charge/charge.vue'),
                meta: {
                    name: '充值'
                }
            },
            {
                path: '/framework/audit',
                name: 'audit',
                component: () => import('/src/views/audit/audit/audit.vue'),
                meta: {
                    name: '审核'
                }
            },
		],
        
	},
  ...login,
  ...charge,
  ...audit,
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach(async (to, from, next) => {
    // 动态载入路由
    let newPath = await dynamicLoad( to, from, next );
    if(newPath) return next(newPath);
    next();
})

export default router;