const ss = window.sessionStorage;
const ls = window.localStorage;
const TABCOUNT = 'tabCount';// 当前tab计数
const TABINDEX = 'tabIndex';// 当前tab索引

/**
 * 获取tab索引
 */
function _getTabIndex(){
  try {
      return JSON.parse(ss.getItem(TABINDEX))||0;
  } catch (err) {
      return null;
  }
}

export let localStorage = {
    getItem(key,tabIndex = _getTabIndex()) {
        try {
            return JSON.parse(ls.getItem(key+tabIndex));
        } catch (err) {
            return null;
        }
    },
    setItem(key, val, tabIndex = _getTabIndex()) {
        ls.setItem(key+tabIndex, JSON.stringify(val));
    },
    clear() {
        ls.clear();
    },
    keys(index) {
        return ls.key(index);
    },
    removeItem(key,tabIndex = _getTabIndex()) {
        ls.removeItem(key+tabIndex);
    },
    /**
     * 设置标签页计数
     */
    getTabTotal(){
      return JSON.parse(ls.getItem(TABCOUNT)||0);
    },
    addTab(){
      let count = this.getTabTotal();
      count++;
      ls.setItem(TABCOUNT, JSON.stringify(count));
    }
}

export default localStorage;