<template>
  <router-view ref="view"></router-view>
</template>
<script>

export default {
  methods: {
    /**
         * 同步数据
         * @param {Array} strs 同步数据的key
         * @param {Number} sourceIndex 源页面索引
         */
        synchronousData( strs, sourceIndex = 0 ) {
            if( !strs || !strs.length ) return;
            const vm = this;
            let localStorage = vm.$localStorage;
            for (let index = 0, len = strs.length; index < len; index++) {
                const attr = strs[index];
                let source = localStorage.getItem( attr, sourceIndex);
                let local = localStorage.getItem( attr );

                // 赋值
                if( source && !local ) {
                    localStorage.setItem( attr, source );
                    local = source;
                }  
                // 同步store
                if( local ){
                    vm.$store.commit( attr, local);
                }
            }
        },
  },
  created() {
        let vm = this;
        let arr = [ 
          'currentUser', // 当前登录用户
        ];
        // 同步数据
        vm.synchronousData( arr, vm.$getQueryString( 'sourceIndex' ) );
	},
  mounted() {
    const vm = this;
    let app = document.getElementById('app');
    app.style.height = (document.documentElement.clientHeight || 0) + 'px';

    vm.$nextTick(function () {
        window.onresize = () => {
          let app = document.getElementById('app');
            app.style.height = (document.documentElement.clientHeight || 0) + 'px';
        }
    });
  }
}
</script>

<style>
#app {
  height: 100vh;
  overflow: auto;
  /* background-color: var(--van-gray-1); */
  background-color: #F0F2F5;
  color: var(--van-gray-7);
}
</style>
