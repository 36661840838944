<template>

  <van-list
      class="nmg-list"
      :class="{
        'nmg-list--no-data': currentFinished && !list.length
      }"
      :finished="currentFinished"
      v-bind="$attrs"
      v-model:loading="currentLoading"
      finished-text="没有更多了"
      :infinite-scroll-distance="10"
      @load="onLoad"
    >

    <van-pull-refresh v-model="isRefreshLoading" @refresh="onRefresh">

      <template  v-for="(item, i) in list" :key="i">
        <slot :row="item"></slot>
      </template>
    
      <img v-if="currentFinished && !list.length" class="nodata" src="./assets/images/nodata@3x.png" width="194" alt="" srcset="">

    </van-pull-refresh>

    

  </van-list>

</template>

<script>
import "./assets/less/index.less";
import {post,get} from "../../../config/request.js";

export default {

  name: 'NmgList',
  
  emits: ['load', 'loaded', 'refresh'],

  props: {
    url: String,
    parameter: Object,
    data: {
      type: Array
    },
    type: {
      type: String,
      default: 'post'
    },
    loading: {
      type: Boolean,
    },
    finished: {
      type: Boolean,
    },
    // 刷新状态
    refreshing: Boolean
  },

  data() {
      return {
          currentLoading: false,
          currentFinished: false,
          list: [],
          pageNumber: 1,
          pageSize: 10,
          // 下拉刷新状态
          isRefreshLoading: false,
          // 参数刷新数据状态
          parameterFlashing: false,
          // 参数刷新时，执行的查询方法，因为参数可能在短时间内多次变化，造成多次查询的问题，这里采用【函数去抖操作】，只执行最后一次的最终值
          parameterFlashFn: function() {},
      }
  },

  watch: {
    parameter: {
      handler(newVal, oldVal) {
        if( newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal) ) {
          this.parameterFlashFn();
        }
      },
      deep: true
    },
    data: {
      handler(newVal) {
        this.list = newVal;
      }
    },
    loading: {
      handler(newVal) {
        this.currentLoading = newVal;
      }
    },
    finished: {
      handler(newVal) {
        this.currentFinished = newVal;
      }
    },
    refreshing: {
      handler(newVal) {
        this.isRefreshLoading = newVal;
      }
    },
  },
  
  methods: {
    init() {
      this.currentFinished = false;
      this.list = [];
      this.pageNumber = 1;
      this.pageSize = 10;
    },
    getData() {
      const vm = this;
      this.currentLoading = true;
      const request = {
        post,
        get
      }
      return request[this.type](this.url, Object.assign({
        // 每页显示数据条数，默认为10
        pageSize: this.pageSize,
        // 当前页码，默认为1
        pageNumber: this.pageNumber,
      }, this.parameter))
      .then(ret => {
        vm.list = vm.list.concat(ret.data.objData.dataList);
        vm.pageNumber++;
        if(ret.data.objData.dataList.length < vm.pageSize) {
          vm.currentFinished = true;
        }
        vm.$emit('loaded',vm.list);
      })
      .finally(ret => {
        vm.currentLoading = false;
        vm.isRefreshLoading = false;
        vm.parameterFlashing = false;
      });
    },
    // **************************************************** EVENT ****************************************************
    onLoad() {
      const vm = this;
      // 如果是 刷新页面或者参数变更导致重新查询装状态，需要等到状态结束后在进行load,避免冲突
      if(this.isRefreshLoading || this.parameterFlashing)return;
      if(this.url) {
        vm.getData();
      } else {
        vm.$emit('load');
      }
    },
    onRefresh() {
      const vm = this;
      // 外部做load操作
      this.init();
      this.isRefreshLoading = true;
      // 如过没有url，证明是外部调用者来显示刷新操作
      if(this.url) {
        this.getData();
      } else {
        vm.$emit('refresh');
      }
    }
  },
  mounted() {
    const vm = this;
    const debounceFunc = this.$debounce(500, (num) => {
        vm.init();
        vm.parameterFlashing = true;
        vm.getData(); 
    });  
    this.parameterFlashFn = debounceFunc;
  }

}
</script>

<style>

</style>