<template>
  <div class="nmg-tab">
    <van-tabs v-bind="$attrs">
      <slot></slot>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'NmgTab',
}
</script>

<style>

</style>