<template>
  <div class="nmg-search-page-result">

    <div class="nmg-search-page-result__wrap" v-if="!isResultPage">
      <!-- 搜索内容 -->
      <nmg-search-page 
        ref="nmgSearchPage" 
        v-bind="$attrs"
        v-model="value"
        :placeholder="placeholder" 
        @search="onSearch"
      ></nmg-search-page>
    </div>

    <div class="nmg-search-page-result__wrap" v-if="isResultPage">
      <!-- 搜索框（纯展示） -->
        <van-nav-bar
          left-arrow
          @click-left="onClickLeft"
          :class="{'has-right': !!$slots.right}"
        >
          <template #title>
            <van-search
              ref="search"
              readonly
              v-model="value"
              :placeholder="placeholder"
              @click="onClickSearchInput"
            >
            <template #left-icon>
              <i class="iconfont icon-chaxun"></i>
            </template>
            </van-search>
          </template>

          <template #right v-if="$slots.right">
            <slot name="right"></slot>
          </template>
        </van-nav-bar>

      <!-- 搜索结果 -->
      <div class="results">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
import './assets/less/index.less'
export default {

  name: 'NmgSearchPageResult',

  props: {
    placeholder: {
      type: String,
      default: '请输入搜索关键词'
    }
  },

  emits: ['search'], 

  data() {
    return {
      // 搜索输入
      value: '',
      // 是否是显示结果页
      isResultPage: false
    }
  },

  watch: {
    isResultPage: {
      handler(newVal) {
        if(!newVal) {
          // this.$refs.nmgSearchPage.focus();
        }
      }
    }
  },

  methods: {
    // ****************************************************************** EVENT ******************************************************************
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickSearchInput() {
      this.isResultPage = false;
    },
    onSearch() {
      this.isResultPage = true;
      this.$emit('search',this.value);
    }
  }
}
</script>

<style>

</style>