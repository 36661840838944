<template>
  <div class="nmg-block" :class="{['nmg-block--' + theme]: true}">
    <h2 class="nmg-block__title" v-if="title">{{title}}</h2>
    <slot></slot>
  </div>
</template>

<script>
import './assets/less/index.less'
export default {

  name: "NmgBlock",

  props: {
    title: String,
    theme: {
      type: String,
      default: 'white'
    }
  }

}
</script>

<style>

</style>