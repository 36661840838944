
/**
 * 深拷贝
 * @param {Object} obj 被拷贝对象
 */
export function deepCopy(obj) {
    // 基本类型： 字符串（String）、数字(Number)、布尔(Boolean)、对空（Null）、未定义（Undefined）、Symbol
    const BASE = [ 
        '[object String]',
        '[object Number]',
        '[object Boolean]',
        '[object Null]',
        '[object Undefined]',
        '[object Symbol]'
    ];
    // 引用数据类型： 对象(Object)、数组(Array)、函数(Function)
    // 补充引用类型：日期(Date)
  return (function fn( obj ) {
    let clonedObj;
    let type = Object.prototype.toString.call( obj );

    if ( BASE.includes( type ) ) { // 处理基本类型
        return obj;
    }

    if(obj instanceof File) return obj; // 处理文件

    const constructor = obj.constructor || Object;
    
    if ( '[object Date]' === type ) {// 日期
        clonedObj = new constructor( obj );
    } else if( '[object Function]' === type ) { // 函数
        clonedObj = new Function( 'return ' + obj.toString() )();
    } else { // Object 和 Array
        clonedObj = new constructor();
    }
    // 迭代引用类型可枚举属性
    Object.entries( obj ).forEach(( [ key, value ] ) => {
        // 避免相互引用对象导致死循环，如initalObj.a = initalObj的情况
        if( key === obj ) return;
        clonedObj [key ] = fn( value );
    })
    return clonedObj;
  })( obj );

}


/**
 * 获取地址栏中的参数值
 * @param {*} name 参数名称
 * @returns { string } 参数值
 */
export function getQueryString( name ) {
    // 匹配值
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    // 匹配地址栏参数
    var r = window.location.search.substr(1).match(reg);
    // 返回第二组匹配到的内容
    if(r!=null)return  unescape(r[2]);
    return null;
}

/**
 * 获取字符串长度
 * @param {Object} str 字符串
 */
 export function getStringLength(str){
    var len = 0;
    for(var i = 0;i < str.length; i++){
      if(str.charCodeAt(i) > 255){//如果是汉字，则字符串长度加2
         len += 2;
      } else{
        len++;
      }
    }
    return len;
}


export default {
    // 深拷贝
    deepCopy, 
    // 获取地址栏参数
    getQueryString,
    // 获取字符串长度
    getStringLength,
}



