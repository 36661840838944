
import { defineComponent } from 'vue'
export default defineComponent({

    name: 'NmgTabs',
  
    render() {
      return (
        <div class="nmg-tabs">
          <van-tabs>
            { this.$slots.default?this.$slots.default():''}
          </van-tabs>
        </div>
      )
    }
})